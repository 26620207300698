import { createApp } from 'vue';
import App from './App.vue';
import router from './modules/router';
import storeInstance from './modules/store';
import axiosInstance from './modules/axios';
import plugin from './modules/plugin';

const vue = createApp(App)
    .use(router)
    .use(storeInstance)
    .use(plugin)
    .provide('$store', storeInstance)
    .provide('$axios', axiosInstance)
    .mount('#app');
