import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const storeInstance = createStore({
    state: {
        apiKey : '',
        itemList : {}
    },
    getters: {
        getApiKey(state) {
            return state.apiKey;
        },
        getItemList(state) {
            return state.itemList;
        }
    },
    mutations: {
        setApiKey(state, value) {
            state.apiKey = value.apiKey;
        },
        setToken(state, value) {
            state.itemList = value;
        }
    },
    plugins : [ createPersistedState({
        paths : [ "apiKey" ] // , "itemList"
    }) ],
});

export default storeInstance;
