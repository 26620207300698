import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/main'
        },
        {
            path: '/main',
            name: 'main-vue',
            component: () => import('@/components/main/Main.vue')
        },
        {
            path: '/miru1',
            name: 'miru1-vue',
            component: () => import('@/components/main/miru1.vue')
        },
        {
            path: '/miru2',
            name: 'miru2-vue',
            component: () => import('@/components/main/miru2.vue')
        },
        {
            path: '/miru3',
            name: 'miru3-vue',
            component: () => import('@/components/main/miru3.vue')
        },
        {
            path: '/hw',
            name: 'hardware-vue',
            component: () => import('@/components/product/hardware.vue')
        },
        {
            path: '/sw',
            name: 'solution-vue',
            component: () => import('@/components/product/solution.vue')
        },
        {
            path: '/ex/list',
            name: 'example-vue',
            component: () => import('@/components/example/list.vue')
        },
        {
            path: '/ex/detail',
            name: 'example-detail-vue',
            component: () => import('@/components/example/detail.vue')
        },
        {
            path: '/inq',
            name: 'inquiry-vue',
            component: () => import('@/components/board/inquiry.vue')
        },
        {
            path: '/tech',
            name: 'techaduapply-vue',
            component: () => import('@/components/board/techaduapply.vue')
        },
        {
            path: '/news',
            name: 'news-vue',
            component: () => import('@/components/board/news.vue')
        },
        {
            path: '/about',
            name: 'about-vue',
            component: () => import('@/components/introduce/about.vue')
        },
        {
            path: '/client',
            name: 'client-vue',
            component: () => import('@/components/introduce/client.vue')
        },
        {
            path: '/history',
            name: 'history-vue',
            component: () => import('@/components/introduce/history.vue')
        },
        {
            path: '/map',
            name: 'map-vue',
            component: () => import('@/components/introduce/map.vue')
        },
    ]
});

router.beforeEach((to, from, next) => {
    // 페이지 리로드를 방지하는 로직 추가
    if (to.hash) {
        next(false); // 페이지 리로드를 방지하기 위해 false 반환
    } else {
        next(); // 계속 진행
    }
});

export default router;
