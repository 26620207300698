import axios from 'axios';
import storeInstance from "@/modules/store";
import router from '@/modules/router';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers : {
        'Content-Type' : 'application/json',
        // 'Access-Control-Allow-Origin': '*'
    },
    timeout : 36000, // timeout
    withCredentials : false, // 요청시 자격증명을 포함할지 여부
    validateStatus : (status) => { // 응답 상태코드 유효성 검사
        if (status >= 200 && status < 400) {
            return true;
        } else {
            return false;
        }
    },
    transformRequest : [ // 요청 데이터 변환 로직
        (data) => {
            return JSON.stringify(data);
        }
    ],
    transformResponse : [ // 응답 데이터 변환 로직
        (data) => {
            return JSON.parse(data);
        }
    ]
});

axiosInstance.interceptors.request.use(
    async function (config) {
        try {
            // axios.defaults.headers.common['Authorization'] = storeInstance.state.apiKey;

            // 파라미터가 null이 아닌 경우에만 추가
            if (config.data) {
                config.data = {
                    ...config.data,
                    apiKey: storeInstance.state.apiKey
                };
            }

            return config;
        } catch (error) {
            router.push({ name : 'main-vue' });
            return Promise.reject(error);
        }
    },
    function (error) {
        router.push({ name : 'main-vue' });
        return Promise.reject(error);
    }
);

export default axiosInstance;
