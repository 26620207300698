const plugin = {
    install(app) {
        app.config.globalProperties.$testMethod = function () {
            alert('요로코롬 쓰면 됩니다요.');
        };

        app.config.globalProperties.$contextMethod = function () {
            // alert('마운트 컨텍스트');
            // context.$data.expiredTimer = '';
        };
    }
};

export default plugin;
