<script setup>
import { RouterView } from 'vue-router'
</script>

<template>
    <header id="headerWrap">
        <h1><img src="/assets/static/img/logo.png" alt="미루웨어" @click="callMain" style="cursor:pointer;"></h1>
        <div class="rightSide">
            <nav id="gnbWrap">
                <ul class="gnb">
                    <li>
                        <a href="javascript:void(0);">솔루션</a>
                        <div class="depthBox tabBox2 sub-wrap" style="height: 250px;">
                            <ul class="nav-depth2">
                                <li>
                                    <a href="javascript:void(0);" class="tab-menu2">MW HPC AI SUPER POD</a>
                                    <div class="depthBox2">
                                        <ul class="nav-depth3">
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '7' })">MW_Entry Pod</a></li>
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '8' })">MW_Mid-Range Pod</a></li>
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '9' })">MW_High-End Pod</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class="tab-menu2">Enterprise K8s Platform</a>
                                    <div class="depthBox2">
                                        <ul class="nav-depth3">
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '1' })">MW OST (Container ORCHESTRATOR)</a></li>
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '2' })">MW Stack(Docker)</a></li>
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '3' })">MW GPU Monitoring Tool</a></li>
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '4' })">RedHat OpenShift</a></li>
                                        </ul>
                                    </div>
                                </li>
<!--                                <li>
                                    <a href="javascript:void(0);" class="tab-menu2">클러스터 파일 시스템
                                    </a>
                                    <div class="depthBox2">
                                        <ul class="nav-depth3">
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '5' })">IBM Spectrum Scale</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class="tab-menu2">HPC 워크로드 관리
                                    </a>
                                    <div class="depthBox2">
                                        <ul class="nav-depth3">
                                            <li><a href="javascript:void(0);" @click="routeVue('solution-vue', { seq : '6' })">IBM Spectrum LSF Suites</a></li>
                                        </ul>
                                    </div>
                                </li>-->
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="javascript:void(0);">하드웨어</a>
                        <div class="depthBox tabBox1 sub-wrap" :style="{ height: menuHeight }">
                            <ul class="nav-depth2">
                                <li v-for="key in orderedKeys" :key="key">
                                    <a href="javascript:void(0);" class="tab-menu">{{ itemNames[key] }}</a>
                                    <div class="depthBox2">
                                        <ul class="nav-depth3" :class="{ 'flex' : menuItems[key].length > 8 }" style="flex-direction: column;height:250px !important;">
                                            <li v-for="(item, key2) in menuItems[key]" :key="key2">
                                                <a href="javascript:void(0);" @click="routeVue('hardware-vue', { seq : item.itemSeq })">{{ item.itemTitle }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li><a href="javascript:void(0);" @click="routeVue('example-vue', null)">구축사례</a><div class="depthBox sub-wrap" style="padding-top:90px;"></div></li>
                    <li>
                        <a href="javascript:void(0);">제품문의</a>
                        <div class="depthBox sub-wrap">
                            <ul class="nav-depth2 nav-flex">
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('inquiry-vue', null)">제품문의 및 견적요청</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('techaduapply-vue', null)">기술교육신청</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('news-vue', null)">뉴스레터</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a href="javascript:void(0);">회사소개</a>
                        <div class="depthBox sub-wrap">
                            <ul class="nav-depth2 nav-flex">
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('about-vue', null)">회사소개</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('history-vue', null)">주요연혁</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('client-vue', null)">주요 고객사</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" @click="routeVue('map-vue', null)">오시는길</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>
            <button class="headerMenu" @click="openNav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                    <path d="M4.05322 18V16H22.8648V18H4.05322ZM4.05322 13V11H22.8648V13H4.05322ZM4.05322 8V6H22.8648V8H4.05322Z" fill="white" />
                </svg>
            </button>
        </div>
    </header>

    <!-- sideMenu -->
    <div id="mySidenav" class="sidenav">
        <div class="h20 colorPrimary sidenavTit">MIRUWARE 파트너</div>
        <a href="javascript:void(0)" class="closebtn color400" @click="closeNav()">&times;</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.gigabyte.com/Enterprise?fromGC')">GIGA Computing</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('http://www.ibm.com/kr-ko')">IBM</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('http://www.amd.com')">AMD</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('http://www.intel.co.kr')">Intel</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.itmi.co.kr/index.php')">명인이노</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.tsne.co.kr')">태성에스엔이</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.metariver.kr')">Metariver Technology</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.wcustom.kr')">웰메이드</a>
        <a href="javascript:void(0)" class="body16 color500" @click="openNewWindow('https://www.aifrica.co.kr')">에이프리카</a>
        <a href="javascript:void(0)" class="body16 color500">서우</a>
        <a href="javascript:void(0)" class="body16 color500">디포그</a>
    </div>

    <router-view :key="$route.fullPath"></router-view>

    <footer>
        <div class="layout-wrap footer-wrap">
            <div class="footer-top">
                <div class="footer-left" style="width:350px !important;" align="center">
                    <div class="footer-logo"><img src="/assets/static/img/main/logo-footer.png" alt="미루웨어"></div>
                    <ul style="width:350px;margin-top: 10px;">
                        <li><a href="javascript:void(0);" @click="routeVue('miru1-vue', null)">미루웨어 윤리규범</a></li>
                        <li><a href="javascript:void(0);" @click="routeVue('miru2-vue', null)">개인정보 처리방침</a></li>
                        <li><a href="javascript:void(0);" @click="routeVue('miru3-vue', null)">AS 정책규정</a></li>
                    </ul>
                </div>
                <div class="footer-right">
                    대표이사 이정훈 | 사업자번호 : 217-81-27760 | TEL : 02-562-8993 | FAX : 02-562-8994 <br>
                    개인정보관리책임자 : 이정훈 | 관리자이메일 : MW@miruware.com <br>
                    05836 주식회사 미루웨어 서울시 송파구 법원로11길 11, B동 501,502호 (문정동, 현대지식산업센터) <br>
                    05836 MIRUWARE Co.,ltd B-501~502, Beobwon-ro 11-gil-11 , Songpa-gu, Seoul , Republic of Korea
                </div>
            </div>
            <div class="footer-bottom">© Copyright 2024 Muruware. All Rights Reserved.</div>
        </div>
    </footer>
</template>

<script>
import { ref, watchEffect } from 'vue';

export default {
    inject: [ '$store', '$axios' ],
    props: true,
    data() {
        return {
            itemNames : {},
            menuItems: {},
            orderedKeys: [],
            menuHeight: 'auto'
        };
    },
    mounted() {
        watchEffect(() => {
            const keysCount = Object.keys(this.menuItems).length;
            this.menuHeight = `${150 + (keysCount * 40)}px`;
        });

        $('#gnbWrap li').off('mouseover').on('mouseover', function () {
            $('.sub-wrap').removeClass('open');
            $(this).children('.sub-wrap').addClass('open');
            if($(this).children('.sub-wrap').hasClass('open')){
                $(this).addClass('focus')
            }
        });

        $('.gnb > li').off('mouseleave').on('mouseleave', function () {
            $(this).children('.sub-wrap').removeClass('open');
            $(this).removeClass('focus');
            $('.tabBox1 .nav-depth3, .tabBox2 .nav-depth3').removeClass('on');
        });

        $(".tab-menu").off('mouseover').on('mouseover', function () {
            let index = $(".tab-menu").index(this);
            $('.tabBox1 .nav-depth3').removeClass('on');
            $('.tabBox1 .nav-depth3:eq(' + index + ')').addClass('on');
        });

        $(".tab-menu2").off('mouseover').on('mouseover', function () {
            let index = $(".tab-menu2").index(this);
            $('.tabBox2 .nav-depth3').removeClass('on');
            $('.tabBox2 .nav-depth3:eq(' + index + ')').addClass('on');
        });

        // this.callMain();
        this.issueApiKey();
    },
    methods: {
        issueApiKey() {
            this.$axios.post('/main/issue', null)
                .then(response => {
                    const data = response.data;
                    if ('0000' == data.code) {
                        this.$store.commit("setApiKey", { apiKey : data.result.key });
                        this.selectItem();
                    }
                })
                .catch(error => {
                    console.error('에러 발생:', error);
                });
        },
        selectItem() {
            this.$axios.post('/hardware/list', { siteCd : 'A000001' })
                .then(response => {
                    const data = response.data;
                    if ('0000' == data.code) {
                        const json = data.result.list;
                        this.menuItems = json.reduce((result, item) => {
                            const keyValue = item['itemCd'];

                            if (!result[keyValue]) {
                                this.itemNames[keyValue] = item['itemName'];
                                result[keyValue] = [];
                            }

                            result[keyValue].push(item);

                            return result;
                        }, {});

                        this.orderedKeys = [ "IT00008", "IT00009", "IT00010", "IT00011", "IT00005", "IT00006", "IT00007", "IT00002" ];
                    }

                    setTimeout(() => {
                        $(".tab-menu").off('mouseover').on('mouseover', function () {
                            let index = $(".tab-menu").index(this);
                            $('.tabBox1 .nav-depth3').removeClass('on');
                            $('.tabBox1 .nav-depth3:eq(' + index + ')').addClass('on');
                        });
                    }, 500);
                })
                .catch(error => {
                    console.error('에러 발생:', error);
                });
        },
        openNav() {
            document.getElementById("mySidenav").style.width = "300px";
            document.getElementById("mySidenav").style.opacity = "1";
        },
        closeNav() {
            document.getElementById("mySidenav").style.width = "0";
            document.getElementById("mySidenav").style.opacity = "0";
        },
        openNewWindow(url) {
            window.open(url, '_blank'); // '_blank'은 새 창에 열기를 의미
        },
        callMain() {
            const currentTime = new Date();
            const currentTimeString = currentTime.toISOString();

            if (this.$router.name == 'main-vue') {
                this.$router.go(0);
            } else {
                this.routeVue('main-vue', null); // { time : currentTimeString }
            }
        },
        routeVue(vue, query) {
            window.scrollTo(0, 0);
            this.$router.push({ name: vue, query: query });
        }
    }
};
</script>

<style>
</style>
